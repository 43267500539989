<!--협찬품 상세 페이지 메인-->
<template>
	<div>
		<bCard>
			<vtb :data="deliveryInf">
				<template #memo="{ v }">
					<div class="btn" @click.stop.prevent="openMemo">
						<b-icon icon="stickies" />
					</div>
				</template>
			</vtb>
			<b-button class="w-40 mx-auto block my-4" @click="edit">변경</b-button>

			<div
				v-for="v in [
					{ title: '핫티스트', data: hottistInf },
					{ title: '협찬품', data: sponsoredInf },
					{ title: '딜', data: dealInf },
				]"
			>
				<h6 class="mt-8 mb-2">{{ v.title }}정보</h6>
				<vtb :data="v.data" titleSize="4" />
			</div>
		</bCard>
	</div>
</template>

<script>
export default {
	props: { dealRequestIdx: { default: '' } },
	data() {
		return {
			deliveryStatusOpts: [],
			deliveryCompanyOpts: [],
			origin: {},
			input: {},

			deliveryInf: [],
			hottistInf: [],
			sponsoredInf: [],
			dealInf: [],
		}
	},
	methods: {
		edit() {
			const input = this.input,
				deliveryStatus =
					typeof input.deliveryStatus == 'string'
						? this.origin.deliveryInfo.deliveryStatusValue
						: getDropdownValue(input.deliveryStatus),
				deliveryCompany =
					typeof input.deliveryCompany == 'string'
						? this.origin.deliveryInfo.deliveryCompanyValue
						: getDropdownValue(input.deliveryCompany),
				invoiceNum = (input.invoiceNum ?? '').replace(/\D/g, ''),
				e = []

			let useInvoiceNum = deliveryCompany || invoiceNum
			//배송사나 운송장 번호를 일단 입력한 경우
			switch (deliveryStatus) {
				case '6':
				case '배송 중':
				case '7':
				case '배송 완료':
				case '19':
				case '구매확정':
					//혹은 배송중 배송완료 구매확정 상태
					useInvoiceNum = true
			}
			if (useInvoiceNum) {
				if (!deliveryCompany) e.push('배송사를 선택해주세요')
				if (!/^\d{8,16}$/.test(input.invoiceNum)) e.push('운송장 번호를 확인해주세요')
			}
			if (e.length) return alert.w(e)

			putApi('api/backoffice/sponsorHandle/updateDeliveryInfo', {
				dealRequestIdx: this.dealRequestIdx,
				deliveryStatus,
				postCode: input.postCode,
				address: input.address,
				address2: input.address2,
				invoiceNum,
				deliveryCompany,
			}).then(() => alert.s('협찬품 상세 정보 수정에 성공했습니다'))
		},
		openMemo() {
			openMemoPopup('SPONSORED_DELIVERY', { dealRequestIdx: this.dealRequestIdx })
		},
	},
	created() {
		if (!this.dealRequestIdx) {
			root.$router.go(-1)
			return alert.w('필수값이 없습니다')
		}

		//configValue 필요한 항목들 가져오기
		getConfig('delivery_status, delivery_company', false, false).then(res => {
			this.deliveryStatusOpts = res.delivery_status.filter(v => {
				switch (v.value) {
					case '2':
					case '6':
					case '7':
					case '18':
					case '20':
						return true
				}
				return false
			})
			this.deliveryCompanyOpts = res.delivery_company

			postApi('api/backoffice/sponsorSelect/sponsoredProductDetail', {
				dealRequestIdx: this.dealRequestIdx,
			}).then(res => {
				this.origin = copyVar(res)
				const { dealInfo, deliveryInfo, hottistInfo, productInfo } = res

				this.input = {
					deliveryStatus: deliveryInfo.deliveryStatus,
					postCode: deliveryInfo.postCode,
					address: deliveryInfo.address,
					address2: deliveryInfo.address2,
					invoiceNum: deliveryInfo.invoiceNum,
					deliveryCompany: deliveryInfo.deliveryCompany,
				}
				//{ title: '주문자명', model: 'input', key: 'fromName', format: 'text' },
				this.deliveryInf = [
					{
						title: '배송 상태',
						model: 'dropdown',
						key: 'deliveryStatus',
						obj: this.input,
						options: this.deliveryStatusOpts,
					},
					{ title: '우편번호', model: 'input', key: 'postCode', obj: this.input },
					{ title: '배송 주소', model: 'input', key: 'address', obj: this.input },
					{ title: '상세 주소', model: 'input', key: 'address2', obj: this.input },
					{ title: '운송장 번호', model: 'input', key: 'invoiceNum', obj: this.input },
					{
						title: '택배사',
						model: 'dropdown',
						key: 'deliveryCompany',
						obj: this.input,
						options: this.deliveryCompanyOpts,
					},
					{ title: '운송장 등록일', cont: deliveryInfo.deliveryRegDate },
					{ title: '배송 시작일', cont: deliveryInfo.deliveryStartDate },
					{ title: '배송 완료일', cont: deliveryInfo.deliveryCompleteDate },
					{ title: '협찬품 메모', model: 'memo' },
				].map((v, i) => {
					if (i > 5 && typeof v.cont == 'string') v.cont = v.cont.replace('T', ' ')
					return v
				})

				this.hottistInf = [
					{ title: '핫트 아이디', cont: hottistInfo.hottId },
					{ title: '이름', cont: hottistInfo.name },
					{ title: '연락처', cont: hottistInfo.phone },
					{ title: '이메일', cont: hottistInfo.email },
				]

				this.sponsoredInf = [
					{ title: 'IDX', cont: productInfo.productGroupIdx },
					{ title: '상품명', cont: productInfo.productGroupName },
					{ title: '상품 코드', cont: productInfo.productNum },
					{ title: '상품 옵션 seq', cont: productInfo.productOptionSeq },
					{ title: '상품 옵션명', cont: productInfo.productOptionName },
				]

				this.dealInf = [
					{ title: '딜요청 idx', cont: dealInfo.dealRequestIdx },
					{ title: '딜 번호', cont: dealInfo.dealNum },
					{ title: '딜 신청일', cont: dealInfo.requestDate },
					{ title: '딜 수락일', cont: dealInfo.acceptDate },
					{ title: '딜 시작일', cont: dealInfo.startDate },
					{ title: '딜 종료일', cont: dealInfo.endDate },
					{ title: '딜 요청 상태', cont: dealInfo.isComplete },
					{ title: '딜 취소일', cont: dealInfo.cancelDate },
				].map(v => {
					if (typeof v.cont == 'string') v.cont = v.cont.replace('T', ' ')
					return v
				})
			})
		})
	},
}
</script>
